import React, { useEffect, useState } from "react";
import './App.css'
// import ReactPlayer from 'react-player'

const Card = (props) => {

  // const { submitter, caption, mp4Src, gifSrc } = props

  const { submitter, gifSrc } = props

  return (
    <>
      {gifSrc &&
        <div style={{height: '350px'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {/* <ReactPlayer 
              url={[{src: src, type: 'video/mp4'}]} 
              controls
              width='256px'
              height='256px'
            /> */}
            <img src={gifSrc} style={{width: '256px', borderRadius: '5px'}} alt="gif" />
          </div>
          <div style={{marginTop: '20px', textAlign: 'center', color: '#fff'}}>
            <span>
              Submitted by{' '}
            </span>
            <span style={{fontWeight: 600}}>
              {submitter}
            </span>
          </div>
        </div>
      }
    </>
  )
}

const App = () => {

  const [adoros, setAdoros] = useState({});
  
  useEffect(() => {
    fetch(`https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_BASE}/Adoro?api_key=${process.env.REACT_APP_AIRTABLE_API}`)
      .then((res) => res.json())
      .then((data) => {
        setAdoros(data.records);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div style={{minHeight: '95vh', background: '#000'}}>
        <div className='app-container'>
          <h1 style={{textAlign: 'center', marginTop: '0', fontSize: '3em'}}>
            <div className='header-container'>
              <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>🕺🕺🕺🕺🕺</span>
              <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Adoro Dance!</span>
              <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>💃💃💃💃💃</span>
            </div>
          </h1>
          <div className='gif-container'>
            {adoros.length > 0 ? (
              adoros.map((adoro) => (
                  <Card
                    submitter={adoro.fields.Submitter}
                    caption={adoro.fields.Caption}
                    mp4Src={adoro.fields.Attachments[0].url}
                    gifSrc={adoro.fields.GIFs[0].url}
                  />
              ))
            ) : (
              <p>Fetching Data...</p>
            )}
          </div>
        </div>
      </div>
      <div style={{minHeight: '5vh', background: '#000', textAlign: 'center'}}>
        <a href="https://www.gradient.run" target="_blank" rel="noreferrer" style={{color: '#fff', fontWeight: '600', textDecoration: 'none'}}>www.gradient.run</a>
      </div>
    </>
  );
}

export default App;